/*
 * @Author: 王广婷
 * @Date: 2019-10-31 09:24:19
 * @LastEditors: 贾峰
 * @LastEditTime: 2019-11-11 16:52:08
 */

import axios from '@/lib/api.request'

// 保单解析列表
export const policyList = data =>
  axios.post('/abd/abtComAbdPolicyImg/list', data)

// 登陆
export const login = data =>
  axios.post('/saas/auth/login', data)

// 刷新token接口
export const refresh = () => axios.get('/saas/auth/refresh')

// 获取APPID
export const getWxURLInfo = () => axios.get('/saas/auth/getWxInfo')
