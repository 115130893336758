<template>
  <div class="interval"
       ref="scroll_bar">
    <div class="list"
         v-if="policyDataList.length">
      <div class="item"
           v-for="(item,index) in policyDataList"
           :key="index">
        <div class="title">{{parseTypeText[item.status]}}</div>
        <div class="body">
          <div class="img">
            <div class="cover"
                 v-if="item.batchtype==='IMG'"
                 @click="preViewImg(item.picAllUrl)">
              <md-swiper :options="swiperOption"
                         :is-prevent="false"
                         v-if="item.picAllUrl.length >= 2">
                <md-swiper-item v-for="(value,index) of item.picAllUrl"
                                :key="index"
                                class="co_swiper"
                                @click="preViewImg(item.picAllUrl)">
                  <div class="imgs">
                    <img :src="ossurl + value + screenshot" />
                  </div>
                </md-swiper-item>
                <div class="swiper-button-prev swiper-button-white"
                     slot="button-prev"></div>
                <div class="swiper-button-next swiper-button-white"
                     slot="button-next"></div>
                <div @click="preViewImg(item.picAllUrl)"
                     style="width:100%;height:100%"></div>
              </md-swiper>
              <div v-else
                   class="onlyimg"
                   @click="preViewImg(item.picAllUrl)">
                <img :src="ossurl+item.picAllUrl[0]+screenshot" />
              </div>
            </div>
            <div class="txtpolicy"
                 v-if="item.batchtype==='TXT'&&item.txtpolicy">
              <div class="textleft">
                <p> <span>保险公司名称：</span> <span>{{item.txtpolicy.mainrisk.companyname}}</span></p>
                <p> <span>产品名称：</span> <span>{{item.txtpolicy.mainrisk.classname}}</span></p>
                <p> <span>保费：</span> <span>{{item.txtpolicy.mainrisk.mount}}</span></p>
                <p> <span>保额：</span> <span>{{item.txtpolicy.mainrisk.amount}}</span></p>
                <p> <span>支付方式：</span> <span>{{item.txtpolicy.mainrisk.paycode}}</span></p>
                <p> <span>缴费年限：</span> <span>{{item.txtpolicy.mainrisk.yearnum}}</span></p>
              </div>
            </div>
            <div class="txtpolicy"
                 v-if="item.batchtype==='BD'">
              <div class="textleft">
                <p> <span>保险公司名称：</span> <span>{{item.txtpolicy.mainrisk.companyname}}</span></p>
                <p> <span>产品名称：</span> <span>{{item.txtpolicy.mainrisk.classname.length > 10 ? (item.txtpolicy.mainrisk.classname.slice(0,14) + '...') : item.txtpolicy.mainrisk.classname}}</span></p>
                <!-- <p> <span>险种类型：</span> <span>{{item.txtpolicy.mainrisk.classtype}}</span></p> -->
                <p> <span>保费：</span> <span>{{item.txtpolicy.mainrisk.mount}}</span></p>
                <p> <span>保额：</span> <span>{{item.txtpolicy.mainrisk.amount}}</span></p>
                <p> <span>支付方式：</span> <span>{{item.txtpolicy.mainrisk.paycode}}</span></p>
                <p> <span>缴费年限：</span> <span>{{item.txtpolicy.mainrisk.yearnum}}</span></p>
              </div>
            </div>
            <div class="btn"
                 v-if="item.status == '0'">
              <router-link tag="span"
                           :to="{path:'/policydetail',query:{comid: item.comid, reportno:item.reportno,policyno:item.policyno,yzm:item.yzm,from:'analysis',personkey:'0',fromwhere:$route.query.fromwhere}}">
                <md-button plain
                           inline
                           type="default"
                           size="small">查看保单</md-button>
              </router-link>
            </div>
            <div class="btn"
                 v-if="item.status == '2'">
              <md-button plain
                         inline
                         type="default"
                         size="small"
                         @click="tobdshow(item,index)">重新上传</md-button>
            </div>
            <div class="line-wrapper">
              <div class="line"></div>
            </div>
          </div>
          <div class="cell">
            <span class='cell_title'>上传时间</span>
            <span class="cell_nr">{{item.intime}}</span>
          </div>
          <div class="cell">
            <span class='cell_title'>{{item.status == '0'?'保单号：':(item.status === '1'? '':'解析失败原因：')}}</span>
            <span class="cell_nr">{{item.status == '0'?item.policyno:(item.status === '1'? '':item.remark)}}</span>
          </div>
          <div class="cell">
            <span class='cell_title'>保单上传批次</span>
            <span class="cell_nr">{{item.batchno}}</span>
          </div>
        </div>
      </div>
      <md-scroll-view-more slot="more"
                           :is-finished="loading" />
    </div>
    <md-result-page :img-url="require('@/assets/abd/image/null.png')"
                    v-else
                    subtext="要不然刷新试试？" />
    <md-image-viewer v-model="viewImgSwitch"
                     :list="viewImgList" />
    <div class="navsetting"
         :style="'background:'+getStorage('theme','')+'!important'">
      <div class="backbtn"
           @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome"
           @click="backtohomepage">
        <span class="iconfont iconhome"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { policyList } from '@/api/abd/analysis'
import loadMore from '@/mixins/loadmore'
import { getStorage } from '@/lib/util'
export default {
  mixins: [loadMore],
  data () {
    return {
      /* 缩略图 */
      screenshot: '?x-oss-process=image/resize,w_100',
      policyDataList: [],
      parseTypeText: [
        '恭喜！您的保单解析成功...',
        '正在努力为您解析保单数据...',
        '抱歉！您的保单解析失败...'
      ],
      pageSize: 50,
      pageNo: 1,
      loading: false,
      total: 0,
      totalPage: 0,
      ossurl: '',
      swiperOption: {
        slidesPerView: 1,
        freeMode: true,
        observer: true,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true
        }
      },
      viewImgSwitch: false,
      viewImgList: []
    }
  },
  created () {
    this.getData()
    this.ossurl = getStorage('ossurl', '')
  },
  mounted () {
  },
  methods: {
    tobdshow (item, index) {
      if (item.status == '2') {
        this.$router.push(
          `/addpolicy?familyno=${item.familyno}&comid=${item.comid}&batchno=${item.batchno}&empno=${item.comempno}&empuserid=${item.empuserid}&policyno=${item.policyno}&personkey=0`
        )
      }
    },
    preViewImg (list) {
      let arr = []
      list.forEach(item => arr.push(this.ossurl + item))
      this.viewImgSwitch = true
      this.viewImgList = arr
    },
    async getData (isInit = true) {
      let { pageSize, pageNo } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let res = await policyList({ pageSize, pageNo })
      res.data.data.rows.forEach(item => { if (item.txtmsg) item.txtpolicy = JSON.parse(item.txtmsg) })
      res.data.data.rows.forEach(item => { if (item.txtmsg && item.batchtype === 'BD') item.txtpolicy = JSON.parse(item.txtmsg)[0] })
      this.policyDataList = isInit ? res.data.data.rows : this.policyDataList.concat(res.data.data.rows)
      this.total = res.data.total
      this.totalPage = res.data.totalPage
      return res
    },
    backtopage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) },
    backtohomepage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) }
  }
}
</script>

<style scoped lang="stylus">
p span {
  color: #036eb5;
}

.onlyimg {
  height: 400px;
}

.imgs {
  width: 100%;
  height: 400px;
}

.onlyimg img, .imgs img {
  object-fit: cover;
}

.icon {
  text-align: center;
}

.list {
  height: 100%;
}

.iconfont {
  font-size: 120px;
}

.success {
  color: #2fb2fa;
}

.error {
  color: #e01504;
}

.line-wrapper {
  padding: 30px 0;
}

.line {
  border-bottom: 1px dashed #e9e9e9;
  position: relative;
}

.line::before {
  position: absolute;
  content: '';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f9f9f9;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.line::after {
  position: absolute;
  content: '';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f9f9f9;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.interval {
  padding: 46px 47px;
  height: 100%;
}

.btn {
  margin-top: 20px;
}

.btn button {
  border: 1px dashed #85888c;
}

.item {
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px #e3e8f7;
  margin-bottom: 20px;
  background: #fff;
}

.item:last-child {
  margin: 0;
}

.body {
  padding-top: 20px;
}

.img {
  text-align: center;
}

.cover {
  width: 800px;
  display: inline-block;
}

.co_swiper {
  width: 879px;
}

.co_img {
  height: 366px;
  width: 343px;
  padding-right: 50px;
}

.title {
  font-size: 36px;
  padding: 20px 0;
  text-align: center;
  color: #1e1e1e;
}

.cell {
  line-height: 60px;
  font-size: 28px;
  color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img a {
  display: block;
  font-size: 26px;
}

.cell_nr {
  color: #666666;
  font-size: 26px;
}

.cell_title {
  color: #1e1e1e;
  font-size: 28px;
}

.txtpolicy {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px dashed #E9E9E9;

  .textleft {
    span:nth-(2n-1) {
      text-align: right;
    }

    width: 100%;

    p {
      display: flex;
      justify-content: space-between;
      padding: 7px 10px;
    }
  }
}

.navsetting {
  width: 10vw;
  height: 20vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.backbtn {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
  }
}

.backtohome {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 50px;
  }
}
</style>
